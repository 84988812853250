import React, { useEffect, useRef, useState } from "react";
import { Facebook } from "react-content-loader";
import { NavLink, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { isEmpty } from "../utils";
import { getUsersRequest } from "../services/UserService";

const ManageUser = () => {
  const location = useLocation();
  const initialRender = useRef(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      await getUsers();
    }

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
      
      fetchUserData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = async () => {
    const response = await getUsersRequest();

    if (response.status === "success") {
      setUsers(response.data);
    } else {
      setUsers([]);
      toast.warning(response.message);
    }
    
    setLoading(false);
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />

      <div className="container-fluid page-body-wrapper">
        <SideNavigation currentPage="manageUser" />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Gestion Utilisateurs</h3>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Nom</th>
                              <th>Prénom(s)</th>
                              <th>E-mail</th>
                              <th>Mode de lecture</th>
                              <th>Interface</th>
                              <th>Lecture</th>
                              <th>Actif</th>
                              <th>Modification</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.map((user) => {
                              return (
                                <tr key={user.id}>
                                  <td>{user.lastName}</td>
                                  <td>{user.firstName}</td>
                                  <td>{user.email}</td>
                                  <td>{user.readingMode}</td>
                                  <td>{isEmpty(user.interfaceLanguage) ? "-" : user.interfaceLanguage}</td>
                                  <td>{isEmpty(user.readingLanguage) ? "-" : user.readingLanguage}</td>
                                  {user.isActive ? (
                                    <td className="text-success">Oui</td>
                                  ) : (
                                    <td className="text-danger">Non</td>
                                  )}
                                  <td>
                                    <NavLink
                                      className="nav-link btn btn-warning"
                                      to={`/manage/user/edit/${user.id}`}
                                    >
                                      <i className="ti-marker-alt text-white"></i>
                                      <span className="menu-title text-white">
                                        {" "}
                                        Modifier
                                      </span>
                                    </NavLink>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default ManageUser;
