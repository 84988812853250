import React from "react";
import ReactSwitch from "react-switch";

const SinglePageTypeForm = ({
  isAvailable,
  setIsAvailable,
  handleUpdateDocuments,
}) => {
  return (
    <div className="row">
      <div className="col-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="form-group">
              <label>
                Fichier source <span className="text-danger">*</span>
              </label>
              <div className="input-group col-xs-12">
                <input
                  type="file"
                  className="form-control file-upload-info"
                  placeholder="Fichier source"
                  required
                  onChange={(e) => {
                    handleUpdateDocuments(e.target.files[0], 0);
                  }}
                />
              </div>
            </div>

            <div className="form-check form-check-flat form-check-primary">
              <label htmlFor="cover-page-form">Disponible</label>
              <div>
                <ReactSwitch
                  checked={isAvailable}
                  onChange={(check) => setIsAvailable(check)}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-primary mr-2">
              Ajouter Page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePageTypeForm;
