import axios from "axios";
import camelize from "camelize";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { Facebook } from "react-content-loader";
import ReactSwitch from "react-switch";
import ImageViewer from "react-simple-image-viewer";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "../utils";
import Select from "react-select";

const EditBook = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { bookId } = useParams();
  const [loading, setLoading] = useState(true);
  const [book, setBook] = useState({});

  const [languages, setLanguages] = useState([]);
  const [chosenLanguages, setChosenLanguages] = useState([]);
  const [backupChosenLanguages, setBackupChosenLanguages] = useState([]);

  const [month, setMonth] = useState("Janvier");
  const [year, setYear] = useState(2022);
  const [pageCount, setPageCount] = useState();
  const [price, setPrice] = useState();
  const [coverPicturePath, setCoverPicturePath] = useState("");
  const [coverPicture, setCoverPicture] = useState(null);
  const [isAvailable, setIsAvailable] = useState(true);

  const [isPictureModificationOpen, setIsPictureModificationOpen] =
    useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  useEffect(() => {
    axios({ method: "GET", url: "http://localhost:5000/languages" })
      .then((response) => {
        if (!isEmpty(response.data.languages)) {
          const temp = [];
          response.data.languages.map((language) => {
            return temp.push({ value: language.id, label: language.name });
          });
          setLanguages(temp);
        } else {
          toast.error("Impossible de récupérer les langues disponibles");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Une erreur interne est survenue. Veuillez recharger la page"
        );
      });
  }, []);

  useEffect(() => {
    axios({ method: "GET", url: `http://localhost:5000/books/${bookId}` })
      .then((response) => {
        if (!isEmpty(response.data)) {
          if (response.data.status) {
            setBook(camelize(response.data));
            updateFields();
          }
        } else {
          toast.error(
            "Une erreur interne est survenue. Veuillez recharger la page"
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book]);

  const updateFields = () => {
    if (!isEmpty(book)) {
      setMonth(book.month);
      setYear(book.year);
      setPageCount(book.pageCount);
      setPrice(book.price);
      setCoverPicturePath(book.coverPicturePath);
      setIsAvailable(!book.locked);

      const temp = [];
      book.languages.map((language) => {
        return temp.push({ value: language.id, label: language.name });
      });
      setChosenLanguages(temp);
      setBackupChosenLanguages(temp);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_type", file.type);
    formData.append("year", year);
    formData.append("month", month);

    await axios({ method: "POST", url: "http://localhost:5000/upload/cover", data: formData })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response.data) {
          if (response.data.status) {
            toast.success("Fichier ajouté avec succès");
          } else {
            toast.error("Ajout du fichier impossible");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Une erreur interne est survenue lors de l'ajout du fichier"
        );
      });
  };

  const updateLanguages = async () => {
    // Supprimer les anciennes langues
    for (let i = 0; i < backupChosenLanguages.length; i++) {
      let match = false;
      for (let j = 0; j < chosenLanguages.length; j++) {
        if (backupChosenLanguages[i] === chosenLanguages[j]) {
          match = true;
          break;
        }
      }

      if (!match) {
        await axios({
          method: "DELETE",
          url: `http://localhost:5000/book_language_constitutes/${bookId}/${backupChosenLanguages[i].value}`,
        })
          .then((response) => {
            console.log(response);
            console.log(response.data);
            if (response.data) {
              if (response.data.status) {
                toast.success("Langue supprimée du IM avec succès");
              } else {
                toast.error("Suppression langue au IM impossible");
              }
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error(
              "Une erreur interne est survenue lors de l'ajout du fichier"
            );
          });
      }
    }

    // Ajouter les nouvelles langues
    for (let i = 0; i < chosenLanguages.length; i++) {
      let match = false;
      for (let j = 0; j < backupChosenLanguages.length; j++) {
        if (chosenLanguages[i] === backupChosenLanguages[j]) {
          match = true;
          break;
        }
      }

      if (!match) {
        const data = {
          book_id: bookId,
          language_id: chosenLanguages[i].value,
        };

        axios({
          method: "POST",
          url: "http://localhost:5000/book_language_constitutes",
          data: data,
        }).then((response) => {
          console.log(response);
          console.log(response.data);
          if (response.data) {
            if (response.data.status) {
              toast.success("Langue attribuée avec succès");
            } else {
              toast.error("Attribution de langue impossible");
            }
          }
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      price: price,
      year: year,
      month: month,
      page_count: pageCount,
      locked: !isAvailable,
    };

    if (isPictureModificationOpen) await uploadFile(coverPicture);
    if (chosenLanguages !== backupChosenLanguages) await updateLanguages();

    await axios({ method: "PUT", url: `http://localhost:5000/books/${bookId}`, data: data })
      .then((response) => {
        if (response.data) {
          if (response.data.status) {
            navigate("/manage/book", {
              state: {
                message: "IM modifié avec succès",
                status: true,
              },
            });
          } else {
            toast.error(
              "Certaines informations existent déjà dans la base de données"
            );
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur interne est survenue. Veuillez réessayer plus tard"
        );
        setLoading(false);
      });
  };

  const handleDelete = async () => {
    setShowModal(false);
    setLoading(true);

    await axios({ method: "DELETE", url: `http://localhost:5000/books/${bookId}` })
      .then((response) => {
        if (response.data) {
          if (response.data.status) {
            navigate("/manage/book", {
              state: {
                message: "IM supprimé avec succès",
                status: true,
              },
            });
          } else {
            toast.error("Suppression de l'IM impossible");
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur interne est survenue. Veuillez réessayer plus tard"
        );
        setLoading(false);
      });
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      {!isViewerOpen && <Navigation />}

      {isViewerOpen && (
        <ImageViewer
          className="priority-high"
          src={[coverPicturePath]}
          onClose={() => setIsViewerOpen(false)}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Suppresion IM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet IM définitivement ainsi que
          toutes ses pages ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>
            Oui
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Non
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container-fluid page-body-wrapper">
        {!isViewerOpen && <SideNavigation />}

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Modification IM</h3>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <form className="forms-sample" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="month-form">Mois</label>
                          <select
                            className="form-control"
                            id="month-form"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                          >
                            <option value={month}>{month}</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="year-form">Année</label>
                          <input
                            type="number"
                            className="form-control"
                            id="year-form"
                            placeholder="Année"
                            required
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="page-count-form">
                            Nombre de pages{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="page-count-form"
                            placeholder="Nombre de pages"
                            required
                            value={pageCount}
                            onChange={(e) => setPageCount(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="price-form">
                            Prix (Ar) <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="price-form"
                            placeholder="Prix (en ariary)"
                            required
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="cover-page-form">
                            Photo de couverture{" "}
                            {isPictureModificationOpen && (
                              <span className="text-danger">*</span>
                            )}
                          </label>
                          <div className="input-group col-xs-12">
                            {isPictureModificationOpen ? (
                              <button
                                type="button"
                                className="btn btn-light mr-2"
                                onClick={() =>
                                  setIsPictureModificationOpen(false)
                                }
                              >
                                Annuler changement
                              </button>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-light mr-2"
                                  onClick={() =>
                                    setIsPictureModificationOpen(true)
                                  }
                                >
                                  Changer
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={() => setIsViewerOpen(true)}
                                >
                                  Afficher
                                </button>
                              </>
                            )}
                          </div>
                          {isPictureModificationOpen && (
                            <div className="input-group col-xs-12 mt-2">
                              <input
                                type="file"
                                id="cover-page-form"
                                className="form-control file-upload-info"
                                placeholder="Veuillez choisir un fichier"
                                required
                                onChange={(e) =>
                                  setCoverPicture(e.target.files[0])
                                }
                              />
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="languages-choice-form">Langues</label>
                          <Select
                            value={chosenLanguages}
                            options={languages}
                            isMulti={true}
                            onChange={(values) => setChosenLanguages(values)}
                            placeholder="Veuillez choisir une ou plusieurs langues"
                          />
                        </div>

                        <div className="form-check form-check-flat form-check-primary">
                          <label htmlFor="is-available-form">Disponible</label>
                          <div>
                            <ReactSwitch
                              checked={isAvailable}
                              onChange={(check) => setIsAvailable(check)}
                              uncheckedIcon={false}
                              checkedIcon={false}
                            />
                          </div>
                        </div>
                        <button type="submit" className="btn btn-warning mr-2 text-white">
                          Modifier IM
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger mr-2"
                          onClick={() => setShowModal(true)}
                        >
                          Supprimer IM
                        </button>
                        <button
                          className="btn btn-secondary mr-2"
                          onClick={() => navigate("/manage/book")}
                        >
                          Annuler
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default EditBook;
