import axios from "axios";
import camelize from "camelize";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { Facebook } from "react-content-loader";
// import ImageViewer from "react-simple-image-viewer";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "../utils";

const EditParagraph = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { pageId, paragraphId } = useParams();
  const [loading, setLoading] = useState(true);
  const [paragraph, setParagraph] = useState({});

  const [order, setOrder] = useState(0);
  const [content, setContent] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");
  // const [coverPicturePath, setCoverPicturePath] = useState("");
  // const [coverPicture, setCoverPicture] = useState(null);

  // const [isPictureModificationOpen, setIsPictureModificationOpen] =
  //   useState(false);
  // const [isViewerOpen, setIsViewerOpen] = useState(false);

  useEffect(() => {
    axios({ method: "GET", url: `http://localhost:5000/paragraphs/id/${paragraphId}` })
      .then((response) => {
        if (!isEmpty(response.data)) {
          if (response.data.status) {
            setParagraph(camelize(response.data));
            updateFields();
          }
        } else {
          toast.error(
            "Une erreur interne est survenue. Veuillez recharger la page"
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragraph]);

  const updateFields = () => {
    if (!isEmpty(paragraph)) {
      setOrder(paragraph.order);
      setContent(paragraph.content);
      setDarkMode(paragraph.darkMode);
      setBackgroundColor(paragraph.backgroundColor);
    }
  };

  // const uploadFile = async (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("file_type", file.type);
  //   formData.append("year", year);
  //   formData.append("month", month);

  //   await axios({ method: "POST", url: "http://localhost:5000/upload/cover", data: formData })
  //     .then((response) => {
  //       console.log(response);
  //       console.log(response.data);
  //       if (response.data) {
  //         if (response.data.status) {
  //           toast.success("Fichier ajouté avec succès");
  //         } else {
  //           toast.error("Ajout du fichier impossible");
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error(
  //         "Une erreur interne est survenue lors de l'ajout du fichier"
  //       );
  //     });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      order: order,
      content: content,
      dark_mode: darkMode,
      background_color: backgroundColor,
    };

    // if (isPictureModificationOpen) await uploadFile(coverPicture);

    await axios({
      method: "PUT",
      url: `http://localhost:5000/paragraphs/${paragraphId}`,
      data: data,
    })
      .then((response) => {
        if (response.data) {
          if (response.data.status) {
            navigate(`/manage/page/${pageId}/paragraph`, {
              state: {
                message: "Paragraphe modifié avec succès",
                status: true,
              },
            });
          } else {
            toast.error(
              "L'ordre pour la page est déjà pris"
            );
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur interne est survenue. Veuillez réessayer plus tard"
        );
        setLoading(false);
      });
  };

  const handleDelete = async () => {
    setShowModal(false);
    setLoading(true);

    await axios({ method: "DELETE", url: `http://localhost:5000/paragraphs/${paragraphId}` })
      .then((response) => {
        if (response.data) {
          if (response.data.status) {
            navigate(`/manage/page/${pageId}/paragraph`, {
              state: {
                message: "Paragraphe supprimé avec succès",
                status: true,
              },
            });
          } else {
            toast.error("Suppression du paragraphe impossible");
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur interne est survenue. Veuillez réessayer plus tard"
        );
        setLoading(false);
      });
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      {/* {!isViewerOpen && <Navigation />} */}
      <Navigation />

      {/* {isViewerOpen && (
        <ImageViewer
          className="priority-high"
          src={[coverPicturePath]}
          onClose={() => setIsViewerOpen(false)}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )} */}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Suppresion IM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet IM définitivement ainsi que
          toutes ses pages ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>
            Oui
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Non
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container-fluid page-body-wrapper">
        {/* {!isViewerOpen && <SideNavigation />} */}
        <SideNavigation />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">
                      Modification Paragraphe
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <form className="forms-sample" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="order-form">
                            Ordre <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="order-form"
                            placeholder="Ordre d'emplacement sur la page"
                            required
                            value={order}
                            min={1}
                            onChange={(e) => setOrder(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="content-form">
                            Contenu <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="content-form"
                            rows="4"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label htmlFor="mode-form">
                            Mode <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="mode-form"
                            value={!darkMode ? "light" : "dark"}
                            onChange={(e) =>
                              e.target.value === "light"
                                ? setDarkMode(false)
                                : setDarkMode(true)
                            }
                          >
                            <option value="light">Clair</option>
                            <option value="dark">Sombre</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="background-color-form">
                            Couleur de fond{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="background-color-form"
                            placeholder="Exemple : #FFFFFF"
                            required
                            value={backgroundColor}
                            onChange={(e) => setBackgroundColor(e.target.value)}
                          />
                        </div>

                        {/* <div className="form-group">
                          <label htmlFor="cover-page-form">
                            Photo de couverture{" "}
                            {isPictureModificationOpen && (
                              <span className="text-danger">*</span>
                            )}
                          </label>
                          <div className="input-group col-xs-12">
                            {isPictureModificationOpen ? (
                              <button
                                type="button"
                                className="btn btn-light mr-2"
                                onClick={() =>
                                  setIsPictureModificationOpen(false)
                                }
                              >
                                Annuler changement
                              </button>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-light mr-2"
                                  onClick={() =>
                                    setIsPictureModificationOpen(true)
                                  }
                                >
                                  Changer
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={() => setIsViewerOpen(true)}
                                >
                                  Afficher
                                </button>
                              </>
                            )}
                          </div>
                          {isPictureModificationOpen && (
                            <div className="input-group col-xs-12 mt-2">
                              <input
                                type="file"
                                id="cover-page-form"
                                className="form-control file-upload-info"
                                placeholder="Veuillez choisir un fichier"
                                required
                                onChange={(e) =>
                                  setCoverPicture(e.target.files[0])
                                }
                              />
                            </div>
                          )}
                        </div> */}

                        <button type="submit" className="btn btn-warning mr-2 text-white">
                          Modifier Paragraphe
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger mr-2"
                          onClick={() => setShowModal(true)}
                        >
                          Supprimer Paragraphe
                        </button>
                        <button
                          className="btn btn-secondary mr-2"
                          onClick={() =>
                            navigate(`/manage/page/${pageId}/paragraph`)
                          }
                        >
                          Annuler
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default EditParagraph;
