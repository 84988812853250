import axios from "axios";
import camelize from "camelize";
import { getApiUri, getHeaders } from "./NetworkService";

export const getBooksRequest = async () => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/books/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des IM";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = error.response.data.detail ? error.response.data.detail : "Aucun IM n'est encore disponible";
    });

  return responseData;
};

export const getBookRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/books/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération de l'IM";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération de l'IM";
    });

  return responseData;
};

export const createBookRequest = async ( year, month, cover, price, isAvailable, languagesIds ) => {
  const uri = getApiUri();

  const formData = new FormData();
  formData.append("year", year);
  formData.append("month", month);
  formData.append("cover", cover);
  formData.append("price", price);
  formData.append("is_locked", !isAvailable);
  formData.append("languages_ids", languagesIds);

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/books/`, data: formData, headers: getHeaders() })
    .then((response) => {
      if (response.status === 201) {
        responseData.status = "success";
        responseData.message = "IM créé avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Création impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Création impossible";
    });

  return responseData;
};

export const updateBookRequest = async ( id, title, year, month, cover, price, available, languages ) => {
  const uri = getApiUri();

  const data = {
    title: title,
    year: year,
    month: month,
    cover: cover,
    price: price,
    is_locked: !available,
    languages: languages,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "PATCH", url: `${uri}/api/v1/books/${id}/`, data: data, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "IM modifié avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Modification impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Modification impossible";
    });

  return responseData;
};

export const deleteBookRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "message": null,
  };

  await axios({ method: "DELETE", url: `${uri}/api/v1/books/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 204) {
        responseData.status = "success";
        responseData.message = "IM supprimé avec succès";
      } else {
        responseData.status = "error";
        responseData.message = "Suppression impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Suppression impossible";
    });

  return responseData;
};
