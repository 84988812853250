import React from "react";
import { useNavigate } from "react-router-dom";
import { logoutUserRequest } from "../services/AuthenticationService";

const Navigation = () => {
  const navigate = useNavigate();

  const logoutRequest = (e) => {
    e.preventDefault();

    logoutUserRequest();
    navigate("/login", {
      state: {
        status: "success",
        message: "Déconnecté avec succès",
      }
    });
  }

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a href="/" className="navbar-brand brand-logo">
          IM Reader Admin
        </a>
        <a href="/" className="navbar-brand brand-logo-mini">
          IM
        </a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          data-toggle="minimize"
        >
          <span className="icon-menu"></span>
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/"
              data-toggle="dropdown"
              id="profileDropdown"
            >
              <img
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                alt="profile"
              />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <a className="dropdown-item" href="/">
                <i className="ti-user text-primary"></i>
                Profil
              </a>
              <a className="dropdown-item" href="/">
                <i className="ti-settings text-primary"></i>
                Paramètres
              </a>
              <button className="dropdown-item text-danger" onClick={(e) => logoutRequest(e)}>
                <i className="ti-power-off"></i>
                Déconnexion
              </button>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navigation;
