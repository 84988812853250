import axios from "axios";
import camelize from "camelize";
import { getApiUri, getHeaders } from "./NetworkService";

export const getUsersRequest = async () => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/users/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = error.response.data.detail ? error.response.data.detail : "Aucun utilisateur ne s'est encore enregistré";
    });

  return responseData;
};

export const getUserRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/users/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération de l'utilisateur";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération de l'utilisateur";
    });

  return responseData;
};

export const getPossessionsRequest = async () => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/possessions/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = error.response.data.detail ? error.response.data.detail : "Aucun paiement n'a encore été fait";
    });

  return responseData;
};
