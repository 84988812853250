import React, { useEffect } from "react";

const SideNavigation = ({ currentPage }) => {
  useEffect(() => {
    const activeItem = document.querySelectorAll(".active");
    if (activeItem.length > 1) {
      if (currentPage === "manageBook" || currentPage === "managePage")
        activeItem[0].classList.remove("active");
      else activeItem[1].classList.remove("active");
    }
  }, [currentPage]);

  const navigation = [
    {
      id: 1,
      name: "Dasbhoard",
      href: "/",
      icon: "icon-grid",
      current: currentPage === "dashboard" ? true : false,
    },
    {
      id: 2,
      name: "Ajout IM",
      href: "/add/book",
      icon: "ti-plus",
      current: currentPage === "addBook" ? true : false,
    },
    {
      id: 3,
      name: "Ajout Page",
      href: "/add/page",
      icon: "ti-plus",
      current: currentPage === "addPage" ? true : false,
    },
    {
      id: 4,
      name: "Gestion IM",
      href: "/manage/book",
      icon: "icon-book",
      current: currentPage === "manageBook" ? true : false,
    },
    {
      id: 5,
      name: "Gestion Pages",
      href: "/manage/page",
      icon: "ti-book",
      current: currentPage === "managePage" ? true : false,
    },
    {
      id: 6,
      name: "Gestion Paiements",
      href: "/manage/payment",
      icon: "ti-money",
      current: currentPage === "managePayment" ? true : false,
    },
    {
      id: 7,
      name: "Gestion Utilisateurs",
      href: "/manage/user",
      icon: "ti-user",
      current: currentPage === "manageUser" ? true : false,
    },
  ];

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {navigation.map((item) => {
          return (
            <li
              key={item.id}
              className={item.current ? "nav-item active" : "nav-item"}
            >
              <a className="nav-link" href={item.href}>
                <i className={item.icon + " menu-icon mb-1"}></i>
                <span className="menu-title mt-1">{item.name}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default SideNavigation;
