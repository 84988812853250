import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Facebook } from "react-content-loader";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { isEmpty } from "../utils";
import { getBooksRequest } from "../services/BookService";
import { getLanguagesRequest } from "../services/LanguageService";
import { getBookLanguagePagesRequest } from "../services/PageService";

const ManageBookPage = () => {
  const location = useLocation();
  const initialRender = useRef(false);
  const [loading, setLoading] = useState(true);

  const [books, setBooks] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [pages, setPages] = useState([]);

  const [bookId, setBookId] = useState(null);
  const [languageId, setLanguageId] = useState(null);

  useEffect(() => {
    const fetchBookData = async () => {
      await getBooks();
      fetchLanguageData();
    }
    
    const fetchLanguageData = async () => {
      await getLanguages();
    }

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchBookData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);

    const fetchPageData = async () => {
      await getPages();
    }

    if (!isEmpty(bookId) && !isEmpty(languageId)) {
      fetchPageData()
    } else {
      setTimeout(setLoading, 1200, false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, languageId]);

  const getBooks = async () => {
    const response = await getBooksRequest();

    if (response.status === "success") {
      setBooks(response.data);
      setBookId(response.data[0].id);
    } else {
      setBooks([]);
      toast.warning(response.message);
    }
  };

  const getLanguages = async () => {
    const response = await getLanguagesRequest();

    if (response.status === "success") {
      setLanguages(response.data);
      setLanguageId(response.data[0].id);
    } else {
      setLanguages([]);
      toast.warning(response.message);
    }
    
    setLoading(false);
  };

  const getPages = async () => {
    const response = await getBookLanguagePagesRequest(bookId, languageId);

    if (response.status === "success") {
      setPages(response.data);
    } else {
      setPages([]);
      toast.warning(response.message);
    }

    setLoading(false);
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />

      <div className="container-fluid page-body-wrapper">
        <SideNavigation currentPage="managePage" />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Gestion Pages</h3>
                  </div>
                  {!loading && (
                    <div className="col-12 col-xl-4">
                      <div className="justify-content-end d-flex">
                        <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                          <a className="btn btn-success" href="/add/page">
                            Ajout nouvelle page
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <>
                <div className="row">
                  <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <form className="forms-sample">
                          <div className="form-group">
                            <label htmlFor="book-select-form">Choix IM</label>
                            <select
                              className="form-control"
                              id="book-select-form"
                              value={bookId}
                              onChange={(e) => setBookId(e.target.value)}
                            >
                              {books.map((book) => {
                                return (
                                  <option
                                    key={book.id}
                                    id={book.id}
                                    value={book.id}
                                  >
                                    {book.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="language-select-form">
                              Choix langue
                            </label>
                            <select
                              className="form-control"
                              id="language-select-form"
                              value={languageId}
                              onChange={(e) => setLanguageId(e.target.value)}
                            >
                              {languages.map((language) => {
                                return (
                                  <option key={language.id} value={language.id}>
                                    {language.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Ordre de page</th>
                                <th>Type</th>
                                <th>Titre</th>
                                <th>Auteur</th>
                                <th>Jour</th>
                                <th>Paragraphe(s)</th>
                                <th>Disponible</th>
                                <th>Modification</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pages.map((page) => {
                                return (
                                  <tr key={page.id}>
                                    <td>{page.order}</td>
                                    <td>{page.typeDisplay}</td>
                                    <td>
                                      {page.type === "daily" ? page.title : "-"}
                                    </td>
                                    <td>
                                      {page.type === "daily" ? page.author : "-"}
                                    </td>
                                    <td>
                                      {page.type === "daily" ? page.dayDate : "-"}
                                    </td>
                                    <td>
                                      {page.type === "daily" ? page.paragraphs.length : "-"}
                                    </td>
                                    {!page.isLocked ? (
                                      <td className="text-success">Oui</td>
                                    ) : (
                                      <td className="text-danger">Non</td>
                                    )}
                                    <td>
                                      <a
                                        className="nav-link btn btn-warning"
                                        href={`/manage/page/edit/${page.id}`}
                                      >
                                        <i className="ti-marker-alt text-white"></i>
                                        <span className="menu-title text-white">
                                          {" "}
                                          Modifier
                                        </span>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default ManageBookPage;
