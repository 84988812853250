import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddNewBook from "./pages/AddNewBook";
import AddNewBookPage from "./pages/AddNewBookPage";
import AddNewParagraph from "./pages/AddNewParagraph";
import Dashboard from "./pages/Dashboard";
import EditBook from "./pages/EditBook";
import EditPage from "./pages/EditPage";
import EditParagraph from "./pages/EditParagraph";
import EditUser from "./pages/EditUser";
import ManageBook from "./pages/ManageBook";
import ManageBookPage from "./pages/ManageBookPage";
import ManageParagraph from "./pages/ManageParagraph";
import ManagePayment from "./pages/ManagePayment";
import ManageUser from "./pages/ManageUser";
import LoginPage from "./pages/LoginPage";
import IndexPage from "./pages/IndexPage";
import NotFoundPage from "./pages/NotFoundPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/home" element={<Dashboard />} />
        <Route path="/add/book" element={<AddNewBook />} />
        <Route path="/add/page" element={<AddNewBookPage />} />
        <Route path="/add/paragraph/:pageId" element={<AddNewParagraph />} />
        <Route path="/manage/book" element={<ManageBook />} />
        <Route path="/manage/page" element={<ManageBookPage />} />
        <Route path="/manage/page/:pageId/paragraph" element={<ManageParagraph />} />
        <Route path="/manage/payment" element={<ManagePayment />} />
        <Route path="/manage/user" element={<ManageUser />} />
        <Route path="/manage/book/edit/:bookId" element={<EditBook />} />
        <Route path="/manage/page/edit/:pageId" element={<EditPage />} />
        <Route path="/manage/page/edit/:pageId/paragraph/:paragraphId" element={<EditParagraph />} />
        <Route path="/manage/user/edit/:userId" element={<EditUser />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
