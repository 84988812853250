import axios from "axios";
import camelize from "camelize";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { Facebook } from "react-content-loader";
import { isEmpty } from "../utils";
import { useLocation, useParams } from "react-router-dom";

const ManageParagraph = () => {
  const location = useLocation();
  const initialRender = useRef(false);
  const { pageId } = useParams();
  
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});
  const [paragraphs, setParagraphs] = useState([]);

  useEffect(() => {
    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios({ method: "GET", url: `http://localhost:5000/pages/${pageId}` })
      .then((response) => {
        if (!isEmpty(response.data)) {
          setPage(camelize(response.data));
        } else {
          toast.error("Page invalide");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Une erreur interne est survenue. Veuillez recharger la page"
        );
      });

    axios({ method: "GET", url: `http://localhost:5000/paragraphs/${pageId}` })
      .then((response) => {
        if (!isEmpty(response.data.paragraphs)) {
          setParagraphs(camelize(response.data.paragraphs));
        } else {
          toast.warning(
            "Aucun paragraphe n'a encore été ajouté pour cette page"
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error(
          "Une erreur interne est survenue. Veuillez recharger la page"
        );
      });
  }, [pageId]);

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />

      <div className="container-fluid page-body-wrapper">
        <SideNavigation />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Gestion Paragraphes</h3>
                  </div>
                  {!loading && (
                    <div className="col-12 col-xl-4">
                      <div className="justify-content-end d-flex">
                        <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                          <a
                            className="btn btn-success"
                            href={`/add/paragraph/${pageId}`}
                          >
                            Ajout nouveau paragraphe
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <>
                <div className="row">
                  <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <form className="forms-sample">
                          <div className="form-group">
                            <label htmlFor="book-select-form">IM</label>
                            <select
                              className="form-control"
                              id="book-select-form"
                              value={page.bookId}
                              disabled
                            >
                              <option value={page.bookId}>{page.book}</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="language-select-form">Langue</label>
                            <select
                              className="form-control"
                              id="language-select-form"
                              value={page.languageId}
                              disabled
                            >
                              <option value={page.languageId}>
                                {page.language}
                              </option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="day-select-form">Jour</label>
                            <select
                              className="form-control"
                              id="day-select-form"
                              value={page.dayDate}
                            >
                              <option value={page.dayDate}>
                                {page.dayDate}
                              </option>
                            </select>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Numéro (ordre)</th>
                                <th>Couleur de fond</th>
                                <th>Mode</th>
                                <th>Nombre de caractères</th>
                                <th>Modification</th>
                              </tr>
                            </thead>
                            <tbody>
                              {paragraphs.map((paragraph) => {
                                return (
                                  <tr key={paragraph.id}>
                                    <td>{paragraph.order}</td>
                                    <td>{paragraph.backgroundColor}</td>
                                    <td>
                                      {!paragraph.darkMode ? "Clair" : "Sombre"}
                                    </td>
                                    <td>{paragraph.content.length}</td>
                                    <td>
                                      <a
                                        className="nav-link btn btn-warning"
                                        href={`/manage/page/edit/${pageId}/paragraph/${paragraph.id}`}
                                      >
                                        <i className="ti-marker-alt text-white"></i>
                                        <span className="menu-title text-white">
                                          {" "}
                                          Modifier
                                        </span>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default ManageParagraph;
