import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Facebook } from "react-content-loader";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { getUsersRequest } from "../services/UserService";
import { getBooksRequest } from "../services/BookService";
import { getToken } from "../services/AuthenticationService";
import { isEmpty, separateNumbers } from "../utils";

const Dashboard = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [books, setBooks] = useState([]);
  const [users, setUsers] = useState([]);
  const [paymentsFree, setPaymentsFree] = useState([]);
  const [paymentsPaid, setPaymentsPaid] = useState([]);

  useEffect(() => {
    const fetchBookData = async () => {
      await getBooks();
      fetchUserData();
    };
    
    const fetchUserData = async () => {
      await getUsers();
    };

    if (initialRender.current) {
      if (isEmpty(getToken())) {
        navigate("/login", {
          state: {
            status: "error",
            message: "Authentification requise",
          }
        });
      } else {
        if (location.state) {
          if (!isEmpty(location.state.message)) {
            if (location.state.status === "success") {
              toast.success(location.state.message);
            } else if (location.state.status === "warning") {
              toast.warning(location.state.message);
            } else {
              toast.error(location.state.message);
            }
            window.history.replaceState({}, document.title);
          }
        }
  
        fetchBookData();
      } 
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBooks = async () => {
    const response = await getBooksRequest();

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setBooks(response.data);
      }
    } else {
      setBooks([]);
      toast.warning(response.message);
    }
  };

  const getUsers = async () => {
    const response = await getUsersRequest();

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setUsers(response.data);
      }
    } else {
      setUsers([]);
      toast.warning(response.message);
    }

    setLoading(false);
  };

  //   axios({ method: "GET", url: "http://localhost:5000/user_book_possessions" })
  //     .then((response) => {
  //       if (!isEmpty(response.data.payments)) {
  //         setPaymentsFree(camelize(response.data.free));
  //         setPaymentsPaid(camelize(response.data.paid));
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.log(error);
  //     });
  // }, []);

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />

      <div className="container-fluid page-body-wrapper">
        <SideNavigation currentPage="dashboard" />

        <div className="main-panel">
          <div className="content-wrapper">
            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-12 grid-margin">
                    <div className="row">
                      <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                        <h3 className="font-weight-bold">
                          Bienvenue cher Admin
                        </h3>
                        <h6 className="font-weight-normal mb-0">
                          IM Reader Admin est l'interface pour gérer les{" "}
                          <span className="text-primary">
                            Isan'andro Mahafinaritra
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 grid-margin stretch-card">
                    <div className="card tale-bg">
                      <div className="card-people pt-0">
                        <img
                          src="images/dashboard/background.png"
                          alt="people"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 grid-margin transparent">
                    <div className="row">
                      <div className="col-md-6 mb-4 stretch-card transparent">
                        <div className="card card-tale">
                          <div className="card-body">
                            <p className="mb-4">Nombre de IM</p>
                            <p className="fs-30 mb-2">{separateNumbers(books.length)}</p>
                            <p>{books.length} (30 derniers jours)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4 stretch-card transparent">
                        <div className="card card-dark-blue">
                          <div className="card-body">
                            <p className="mb-4">Nombre d'utilisateurs</p>
                            <p className="fs-30 mb-2">{separateNumbers(users.length)}</p>
                            <p>{separateNumbers(users.length)} (30 derniers jours)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                        <div className="card card-light-blue">
                          <div className="card-body">
                            <p className="mb-4">IM vendus</p>
                            <p className="fs-30 mb-2">{separateNumbers(paymentsPaid.length)}</p>
                            <p>{separateNumbers(paymentsPaid.length)} (30 derniers jours)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 stretch-card transparent">
                        <div className="card card-light-danger">
                          <div className="card-body">
                            <p className="mb-4">IM gratuits récupérés</p>
                            <p className="fs-30 mb-2">{separateNumbers(paymentsFree.length)}</p>
                            <p>{separateNumbers(paymentsFree.length)} (30 derniers jours)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card position-relative">
                      <div className="card-body">
                        <div
                          id="detailedReports"
                          className="position-static pt-2"
                        >
                          <div className="row">
                            <div className="col-md-6 col-xl-3 d-flex flex-column justify-content-start">
                              <div className="ml-xl-4 mt-3">
                                <p className="card-title">Utilisateurs</p>
                                <h1 className="text-primary">{separateNumbers(0)}</h1>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="table-responsive mb-3 mb-md-0 mt-3">
                                    <table className="table table-borderless report-table">
                                      <tr>
                                        <td className="text-muted">Malagasy</td>
                                        <td className="w-100 px-0">
                                          <div className="progress progress-md mx-4">
                                            <div
                                              className="progress-bar bg-info w-100"
                                              role="progressbar"
                                              aria-valuenow="70"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </td>
                                        <td>
                                          <h5 className="font-weight-bold mb-0">
                                            0
                                          </h5>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-muted">Français</td>
                                        <td className="w-100 px-0">
                                          <div className="progress progress-md mx-4">
                                            <div
                                              className="progress-bar bg-warning w-100"
                                              role="progressbar"
                                              aria-valuenow="30"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </td>
                                        <td>
                                          <h5 className="font-weight-bold mb-0">
                                            0
                                          </h5>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-muted">Anglais</td>
                                        <td className="w-100 px-0">
                                          <div className="progress progress-md mx-4">
                                            <div
                                              className="progress-bar bg-danger w-100"
                                              role="progressbar"
                                              aria-valuenow="95"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </td>
                                        <td>
                                          <h5 className="font-weight-bold mb-0">
                                            0
                                          </h5>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-muted">Autres</td>
                                        <td className="w-100 px-0">
                                          <div className="progress progress-md mx-4">
                                            <div
                                              className="progress-bar bg-primary w-100"
                                              role="progressbar"
                                              aria-valuenow="60"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </td>
                                        <td>
                                          <h5 className="font-weight-bold mb-0">
                                            0
                                          </h5>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
