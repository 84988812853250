import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../services/AuthenticationService";
import { isEmpty } from "../utils";

const IndexPage = () => {
  const initialRender = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (initialRender.current) {
      if (isEmpty(getToken())) {
        navigate("/login");
      } else {
        // Check if the token is expired
        navigate("/home");
      }
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default IndexPage;
