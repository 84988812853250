import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Facebook } from "react-content-loader";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { isEmpty } from "../utils";
import SinglePageTypeForm from "../forms/SinglePageTypeForm";
import DailyPageTypeForm from "../forms/DailyPageTypeForm";
import { getBooksRequest } from "../services/BookService";
import { getLanguagesRequest } from "../services/LanguageService";
import { createPageRequest } from "../services/PageService";

const AddNewBookPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [books, setBooks] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [pageCount, setPageCount] = useState(2);

  const [type, setType] = useState("single");
  const [order, setOrder] = useState();
  const [title, setTitle] = useState(null);
  const [author, setAuthor] = useState("Bishop Patrick");
  const [day, setDay] = useState(null);
  const [dayDate, setDayDate] = useState(null);
  const [bibleVerse, setBibleVerse] = useState(null);
  const [bibleVerseSource, setBibleVerseSource] = useState(null);
  const [faithConfession, setFaithConfession] = useState(null);
  const [meditateQuestion, setMeditateQuestion] = useState(null);
  const [meditateVerse, setMeditateVerse] = useState(null);
  const [meditateVerseSource, setMeditateVerseSource] = useState(null);
  const [dailyVerseMorning, setDailyVerseMorning] = useState(null);
  const [dailyVerseEvening, setDailyVerseEvening] = useState(null);

  const [documents, setDocuments] = useState([null, null, null, null]);

  const [isAvailable, setIsAvailable] = useState(true);
  const [bookId, setBookId] = useState(null);
  const [languageId, setLanguageId] = useState(null);

  useEffect(() => {
    const fetchBookData = async () => {
      await getBooks();
      fetchLanguageData();
    }

    const fetchLanguageData = async () => {
      await getLanguages();
    }

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchBookData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBooks = async () => {
    const response = await getBooksRequest();

    if (response.status === "success") {
      setBooks(response.data);
      setBookId(response.data[0].id);
    } else {
      setBooks([]);
      toast.warning(response.message);
    }
  };

  const getLanguages = async () => {
    const response = await getLanguagesRequest();

    if (response.status === "success") {
      setLanguages(response.data);
      setLanguageId(response.data[0].id);
    } else {
      setLanguages([]);
      toast.warning(response.message);
    }

    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await createPageRequest(
      bookId,
      languageId,
      type,
      order,
      title,
      author,
      dayDate,
      day,
      bibleVerse,
      bibleVerseSource,
      faithConfession,
      meditateQuestion,
      meditateVerse,
      meditateVerseSource,
      dailyVerseMorning,
      dailyVerseEvening,
      isAvailable,
      pageCount,
      documents
    );

    if (response.status === "success") {
      toast.success(response.message);
      setLoading(false);
    } else {
      toast.error(response.message);
      setLoading(false);
    }
  };

  const handleUpdateDocuments = (file, index) => {
    const newDocuments = [...documents];
    newDocuments[index] = file;
    setDocuments(newDocuments);
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />
      <div className="container-fluid page-body-wrapper">
        <SideNavigation currentPage="addPage" />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Ajout Page IM</h3>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <form className="forms-sample" enctype="multipart/form-data" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="book-select-form">
                            Choix IM <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="book-select-form"
                            value={bookId}
                            onChange={(e) => setBookId(e.target.value)}
                          >
                            {books.map((book) => {
                              return (
                                <option
                                  key={book.id}
                                  id={book.id}
                                  value={book.id}
                                >
                                  {book.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="language-select-form">
                            Choix langue <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="language-select-form"
                            value={languageId}
                            onChange={(e) => setLanguageId(e.target.value)}
                          >
                            {languages.map((language) => {
                              return (
                                <option key={language.id} value={language.id}>
                                  {language.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="page-type-form">
                            Type de page <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="page-type-form"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value="single">Une page</option>
                            <option value="daily">Lecture quotidienne</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="page-order-form">
                            Ordre de page{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="page-order-form"
                            placeholder="Ordre de page"
                            min={1}
                            required
                            value={order}
                            onChange={(e) => setOrder(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {type === "single" ? (
                  <SinglePageTypeForm
                    isAvailable={isAvailable}
                    setIsAvailable={setIsAvailable}
                    handleUpdateDocuments={handleUpdateDocuments}
                  />
                ) : (
                  <DailyPageTypeForm
                    isAvailable={isAvailable}
                    setIsAvailable={setIsAvailable}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    handleUpdateDocuments={handleUpdateDocuments}
                    title={title}
                    setTitle={setTitle}
                    author={author}
                    setAuthor={setAuthor}
                    day={day}
                    setDay={setDay}
                    dayDate={dayDate}
                    setDayDate={setDayDate}
                    bibleVerse={bibleVerse}
                    setBibleVerse={setBibleVerse}
                    bibleVerseSource={bibleVerseSource}
                    setBibleVerseSource={setBibleVerseSource}
                    faithConfession={faithConfession}
                    setFaithConfession={setFaithConfession}
                    meditateQuestion={meditateQuestion}
                    setMeditateQuestion={setMeditateQuestion}
                    meditateVerse={meditateVerse}
                    setMeditateVerse={setMeditateVerse}
                    meditateVerseSource={meditateVerseSource}
                    setMeditateVerseSource={setMeditateVerseSource}
                    dailyVerseMorning={dailyVerseMorning}
                    setDailyVerseMorning={setDailyVerseMorning}
                    dailyVerseEvening={dailyVerseEvening}
                    setDailyVerseEvening={setDailyVerseEvening}
                  />
                )}
              </form>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default AddNewBookPage;
