import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Facebook } from "react-content-loader";
import { ToastContainer, toast } from "react-toastify";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { isEmpty, separateNumbers } from "../utils";
import { getPossessionsRequest } from "../services/UserService";

const ManagePayment = () => {
  const location = useLocation();
  const initialRender = useRef(false);
  const [loading, setLoading] = useState(false);
  const [possessions, setPossesions] = useState([]);

  useEffect(() => {
    const fetchPosessionData = async () => {
      await getPossessions();
    }

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchPosessionData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPossessions = async () => {
    const response = await getPossessionsRequest();

    if (response.status === "success") {
      setPossesions(response.data);
    } else {
      setPossesions([]);
      toast.warning(response.message);
    }
    
    setLoading(false);
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />

      <div className="container-fluid page-body-wrapper">
        <SideNavigation currentPage="managePayment" />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Gestion Paiements</h3>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Utilisateur</th>
                              <th>IM</th>
                              <th>Prix</th>
                              <th>Référence</th>
                              <th>Date d'acquisition</th>
                            </tr>
                          </thead>
                          <tbody>
                            {possessions.map((possession) => {
                              return (
                                <tr key={possession.id}>
                                  <td>{possession.user.lastname} {possession.user.firstName}</td>
                                  <td>{possession.book.title}</td>
                                  <td>{separateNumbers(possession.price)} Ar</td>
                                  <td>{possession.reference}</td>
                                  <td>{possession.createdAt}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default ManagePayment;
