import axios from "axios";
import camelize from "camelize";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { Facebook } from "react-content-loader";
import ReactSwitch from "react-switch";
import ImageViewer from "react-simple-image-viewer";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "../utils";

const EditPage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { pageId } = useParams();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});
  const [book, setBook] = useState("");
  const [language, setLanguage] = useState("");
  const [isDailyPage, setIsDailyPage] = useState(false);
  const [pageCount, setPageCount] = useState(2);

  const [pageNumber, setPageNumber] = useState();
  const [title, setTitle] = useState(null);
  const [author, setAuthor] = useState(null);
  const [day, setDay] = useState(null);
  const [dayDate, setDayDate] = useState(null);
  const [bibleVerse, setBibleVerse] = useState(null);
  const [bibleVerseSource, setBibleVerseSource] = useState(null);
  const [faithConfession, setFaithConfession] = useState(null);
  const [meditateQuestion, setMeditateQuestion] = useState(null);
  const [meditateVerse, setMeditateVerse] = useState(null);
  const [meditateVerseSource, setMeditateVerseSource] = useState(null);
  const [dailyVerseMorning, setDailyVerseMorning] = useState(null);
  const [dailyVerseEvening, setDailyVerseEvening] = useState(null);

  const [firstContent, setFirstContent] = useState(null);
  const [secondContent, setSecondContent] = useState(null);
  const [thirdContent, setThirdContent] = useState(null);
  const [fourthContent, setFourthContent] = useState(null);

  const [firstContentPath, setFirstContentPath] = useState(null);
  const [secondContentPath, setSecondContentPath] = useState(null);
  const [thirdContentPath, setThirdContentPath] = useState(null);
  const [fourthContentPath, setFourthContentPath] = useState(null);

  const [isAvailable, setIsAvailable] = useState(true);

  const [isPictureModificationOpen, setIsPictureModificationOpen] =
    useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  useEffect(() => {
    axios({ method: "GET", url: `http://localhost:5000/pages/${pageId}` })
      .then((response) => {
        if (!isEmpty(response.data)) {
          if (response.data.status) {
            setPage(camelize(response.data));
            updateFields();
          }
        } else {
          toast.error(
            "Une erreur interne est survenue. Veuillez recharger la page"
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const updateFields = async () => {
    if (!isEmpty(page)) {
      setBook(page.book);
      setLanguage(page.language);
      setPageNumber(page.pageNumber);
      setTitle(page.title);
      setAuthor(page.author);
      setDay(page.day);
      setDayDate(page.dayDate);
      setBibleVerse(page.bibleVerse);
      setBibleVerseSource(page.bibleVerseSource);
      setFaithConfession(page.faithConfession);
      setMeditateQuestion(page.meditateQuestion);
      setMeditateVerse(page.meditateVerse);
      setMeditateVerseSource(page.meditateVerseSource);
      setDailyVerseMorning(page.dailyVerseMorning);
      setDailyVerseEvening(page.dailyVerseEvening);
      setFirstContentPath(page.firstContentPath);
      setSecondContentPath(page.secondContentPath);
      setThirdContentPath(page.thirdContentPath);
      setFourthContentPath(page.fourthContentPath);
      setIsDailyPage(page.isDailyPage);
      setIsAvailable(!page.locked);
    }
  };

  // const uploadFile = async (file, partNumber) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("file_type", file.type);
  //   formData.append("book_id", bookId);
  //   formData.append("language_id", languageId);
  //   formData.append("page_number", pageNumber);
  //   formData.append("part_number", partNumber);

  //   await axios({ method: "POST", url: "http://localhost:5000/upload/page", data: formData })
  //     .then((response) => {
  //       console.log(response);
  //       console.log(response.data);
  //       if (response.data) {
  //         if (response.data.status) {
  //           toast.success("Fichier ajouté avec succès");
  //         } else {
  //           toast.error("Ajout du fichier impossible");
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error(
  //         "Une erreur interne est survenue lors de l'ajout du fichier"
  //       );
  //     });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      page_number: pageNumber,
      title: title,
      author: author,
      day: day,
      day_date: dayDate,
      bible_verse: bibleVerse,
      bible_verse_source: bibleVerseSource,
      faith_confession: faithConfession,
      meditate_question: meditateQuestion,
      meditate_verse: meditateVerse,
      meditate_verse_source: meditateVerseSource,
      daily_verse_morning: dailyVerseMorning,
      daily_verse_evening: dailyVerseEvening,
      is_daily_page: isDailyPage,
      locked: !isAvailable,
    };

    // if (isPictureModificationOpen) await uploadFile(coverPicture);

    await axios({ method: "PUT", url: `http://localhost:5000/pages/${pageId}`, data: data })
      .then((response) => {
        if (response.data) {
          if (response.data.status) {
            navigate("/manage/page", {
              state: {
                message: "Page modifiée avec succès",
                status: true,
              },
            });
          } else {
            toast.error(
              "Certaines informations existent déjà dans la base de données"
            );
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur interne est survenue. Veuillez réessayer plus tard"
        );
        setLoading(false);
      });
  };

  const handleDelete = async () => {
    setShowModal(false);
    setLoading(true);

    await axios({ method: "DELETE", url: `http://localhost:5000/pages/${pageId}` })
      .then((response) => {
        if (response.data) {
          if (response.data.status) {
            navigate("/manage/page", {
              state: {
                message: "Page supprimée avec succès",
                status: true,
              },
            });
          } else {
            toast.error("Suppression de la page impossible");
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur interne est survenue. Veuillez réessayer plus tard"
        );
        setLoading(false);
      });
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      {!isViewerOpen && <Navigation />}

      {isViewerOpen && (
        <ImageViewer
          className="priority-high"
          src={
            isDailyPage
              ? [
                  firstContentPath,
                  secondContentPath,
                  thirdContentPath,
                  fourthContentPath,
                ]
              : [firstContentPath]
          }
          onClose={() => setIsViewerOpen(false)}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Suppresion Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cette page définitivement ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>
            Oui
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Non
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container-fluid page-body-wrapper">
        {!isViewerOpen && <SideNavigation />}

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Modification Page IM</h3>
                  </div>
                  {!loading && isDailyPage && (
                    <div className="col-12 col-xl-4">
                      <div className="justify-content-end d-flex">
                        <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                          <a
                            className="btn btn-info"
                            href={`/manage/page/${pageId}/paragraph`}
                          >
                            Gestion paragraphes
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="book-select-form">IM</label>
                          <select
                            className="form-control"
                            id="book-select-form"
                          >
                            <option>{book}</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="language-select-form">Langue</label>
                          <select
                            className="form-control"
                            id="language-select-form"
                          >
                            <option>{language}</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="page-type-form">Type</label>
                          <select className="form-control" id="page-type-form">
                            <option>
                              {isDailyPage ? "Page quotidienne" : "Une page"}
                            </option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="page-number-form">
                            Numéro de page{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="page-number-form"
                            placeholder="Numéro de page"
                            min={1}
                            required
                            value={pageNumber}
                            onChange={(e) => setPageNumber(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        {!isDailyPage ? (
                          <div className="form-group">
                            <label htmlFor="first-content-form">
                              Fichier source{" "}
                              {isPictureModificationOpen && (
                                <span className="text-danger">*</span>
                              )}
                            </label>
                            <div className="input-group col-xs-12">
                              {isPictureModificationOpen ? (
                                <button
                                  type="button"
                                  className="btn btn-light mr-2"
                                  onClick={() =>
                                    setIsPictureModificationOpen(false)
                                  }
                                >
                                  Annuler changement
                                </button>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-light mr-2"
                                    onClick={() =>
                                      setIsPictureModificationOpen(true)
                                    }
                                  >
                                    Changer
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => setIsViewerOpen(true)}
                                  >
                                    Afficher
                                  </button>
                                </>
                              )}
                            </div>
                            {isPictureModificationOpen && (
                              <div className="input-group col-xs-12 mt-2">
                                <input
                                  type="file"
                                  id="first-content-form"
                                  className="form-control file-upload-info"
                                  placeholder="Fichier source"
                                  required
                                  onChange={(e) => {
                                    setFirstContent(e.target.files[0]);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="form-group">
                              <label htmlFor="page-count-form">
                                Nombre de pages{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="page-count-form"
                                value={pageCount}
                                onChange={(e) => setPageCount(e.target.value)}
                              >
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="title-form">
                                Titre <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="title-form"
                                placeholder="Thème du jour"
                                required
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="author-form">
                                Auteur <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="author-form"
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}
                              >
                                <option value="Bishop Patrick">
                                  Bishop Patrick
                                </option>
                                <option value="Révérend Fara">
                                  Révérend Fara
                                </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="day-form">
                                Jour <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="day-form"
                                placeholder="Exemple : 15"
                                required
                                value={day}
                                onChange={(e) => setDay(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="day-date-form">
                                Jour Complet{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="day-date-form"
                                placeholder="Exemple : Alakamisy 03 Martsa"
                                required
                                value={dayDate}
                                onChange={(e) => setDayDate(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="verse-form">Verset</label>
                              <textarea
                                className="form-control"
                                id="verse-form"
                                rows="4"
                                value={bibleVerse}
                                onChange={(e) => setBibleVerse(e.target.value)}
                              ></textarea>
                            </div>
                            <div className="form-group">
                              <label htmlFor="verse-source-form">
                                Source du verset
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="verse-source-form"
                                placeholder="Exemple : Salamo 105:1"
                                value={bibleVerseSource}
                                onChange={(e) =>
                                  setBibleVerseSource(e.target.value)
                                }
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="faith-confession-form">
                                Confession de foi
                              </label>
                              <textarea
                                className="form-control"
                                id="faith-confession-form"
                                rows="4"
                                value={faithConfession}
                                onChange={(e) =>
                                  setFaithConfession(e.target.value)
                                }
                              ></textarea>
                            </div>
                            <div className="form-group">
                              <label htmlFor="meditate-question-form">
                                Méditation du jour
                              </label>
                              <textarea
                                className="form-control"
                                id="meditate-question-form"
                                rows="4"
                                value={meditateQuestion}
                                onChange={(e) =>
                                  setMeditateQuestion(e.target.value)
                                }
                              ></textarea>
                            </div>

                            <div className="form-group">
                              <label htmlFor="meditate-verse-form">
                                Verset à méditer
                              </label>
                              <textarea
                                className="form-control"
                                id="meditate-verse-form"
                                rows="4"
                                value={meditateVerse}
                                onChange={(e) =>
                                  setMeditateVerse(e.target.value)
                                }
                              ></textarea>
                            </div>
                            <div className="form-group">
                              <label htmlFor="meditate-verse-source-form">
                                Source du verset à méditer
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="meditate-verse-source-form"
                                placeholder="Exemple : Salamo 107:11"
                                value={meditateVerseSource}
                                onChange={(e) =>
                                  setMeditateVerseSource(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="daily-verse-morning-form">
                                Verset(s) du jour matinée
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="daily-verse-morning-form"
                                placeholder="Salamo 15"
                                value={dailyVerseMorning}
                                onChange={(e) =>
                                  setDailyVerseMorning(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="daily-verse-evening-form">
                                Verset(s) du jour soirée
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="daily-verse-evening-form"
                                placeholder="Ohabolana 30-31"
                                value={dailyVerseEvening}
                                onChange={(e) =>
                                  setDailyVerseEvening(e.target.value)
                                }
                              />
                            </div>

                            <div className="form-group">
                              <label>
                                Fichier source 1{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group col-xs-12">
                                <input
                                  type="file"
                                  className="form-control file-upload-info"
                                  placeholder="Fichier source"
                                  onChange={(e) => {
                                    setFirstContent(e.target.files[0]);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label>
                                Fichier source 2{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group col-xs-12">
                                <input
                                  type="file"
                                  className="form-control file-upload-info"
                                  placeholder="Fichier source 2"
                                  onChange={(e) => {
                                    setSecondContent(e.target.files[0]);
                                  }}
                                />
                              </div>
                            </div>
                            {parseInt(pageCount) > 2 && (
                              <div className="form-group">
                                <label>
                                  Fichier source 3{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="input-group col-xs-12">
                                  <input
                                    type="file"
                                    className="form-control file-upload-info"
                                    placeholder="Fichier source 3"
                                    onChange={(e) => {
                                      setThirdContent(e.target.files[0]);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            {parseInt(pageCount) > 3 && (
                              <div className="form-group">
                                <label>
                                  Fichier source 4{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="input-group col-xs-12">
                                  <input
                                    type="file"
                                    className="form-control file-upload-info"
                                    placeholder="Fichier source 4"
                                    onChange={(e) => {
                                      setFourthContent(e.target.files[0]);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        <div className="form-check form-check-flat form-check-primary">
                          <label htmlFor="is-available-form">Disponible</label>
                          <div>
                            <ReactSwitch
                              checked={isAvailable}
                              onChange={(check) => setIsAvailable(check)}
                              uncheckedIcon={false}
                              checkedIcon={false}
                            />
                          </div>
                        </div>
                        <button type="submit" className="btn btn-warning mr-2 text-white">
                          Modifier Page
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger mr-2"
                          onClick={() => setShowModal(true)}
                        >
                          Supprimer Page
                        </button>
                        <button
                          className="btn btn-secondary mr-2"
                          onClick={() => navigate("/manage/page")}
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default EditPage;
