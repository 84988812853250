import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { TailSpin } from 'react-loader-spinner';
import { isEmpty } from "../utils";
import { checkUserAdminRequest, getToken, loginUserRequest, logoutUserRequest, storeToken } from "../services/AuthenticationService";

const LoginPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (initialRender.current) {
      if (!isEmpty(getToken())) {
        navigate("/home");
      }

      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await loginUserRequest(email, password);

    if (response.status === "success") {
      storeToken(response.data.access, response.data.refresh);
      if (await checkUserAdmin()) {
        navigate("/home", {
          state: {
            status: "success",
            message: response.message,
          }
        });
      } else {
        logoutUserRequest();
        setLoading(false);
      }
    } else if (response.status === "warning") {
      setLoading(false);
      toast.warning(response.message);
    }
     else {
      setLoading(false);
      toast.error(response.message);
    }
  }

  const checkUserAdmin = async () => {
    const response = await checkUserAdminRequest();

    if (response.status === "success") {
      return true;
    } else {
      toast.error(response.message);
      return false;
    }
  }

  return (
    <div className="container-scroller">
      <ToastContainer />

      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <TailSpin
                  visible={true}
                  height={85}
                  width={85}
                  color="#4B49AC"
                  ariaLabel="tail-spin-loading"
                  radius={1}
                  strokeWidth={4}
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo text-center">
                  <h3 className="text-primary font-weight-medium">Isan'andro Mahafinaritra Admin</h3>
                </div>
                <h6 className="font-weight-light text-center">Veuillez vous connecter pour continuer</h6>
                <form className="pt-3" onSubmit={(e) => handleFormSubmit(e)}>
                  <div className="form-group">
                    <input type="email" className="form-control form-control-lg" id="email" placeholder="Adresse e-mail" required value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control form-control-lg" id="password" placeholder="Mot de passe" required value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className="mt-3">
                    <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">Se connecter</button>
                  </div>
                </form>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default LoginPage;
