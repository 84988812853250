import axios from "axios";
import camelize from "camelize";
import { getApiUri, getHeaders } from "./NetworkService";
import { isEmpty } from "../utils";

export const getPagesRequest = async (bookId) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/books/${bookId}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des IM";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = error.response.data.detail ? error.response.data.detail : "Aucun page n'est encore disponible";
    });

  return responseData;
};

export const getBookLanguagePagesRequest = async ( bookId, languageId ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/books/${bookId}/languages/${languageId}/pages/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des pages de l'IM pour cette langue";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = error.response.data.detail ? error.response.data.detail : "Aucun page n'est encore disponible pour cet IM avec cette langue";
    });

  return responseData;
};

export const getPageRequest = async ( bookId, id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/books/${bookId}/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération de la page";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération de la page";
    });

  return responseData;
};

export const createPageRequest = async (
    bookId,
    languageId,
    type,
    order,
    title,
    author,
    dayDate,
    day,
    bibleVerse,
    bibleVerseSource,
    faithConfession,
    meditateQuestion,
    meditateVerse,
    meditateVerseSource,
    dailyVerseMorning,
    dailyVerseEvening,
    isAvailable,
    pageCount,
    documents
) => {
  const uri = getApiUri();

  const formData = new FormData();
  formData.append("book_id", bookId);
  formData.append("language_id", languageId);
  formData.append("type", type);
  formData.append("order", order);
  formData.append("title", title);
  formData.append("author", author);
  formData.append("day_date", dayDate);
  formData.append("day", !isEmpty(day) ? day : 0);
  formData.append("bible_verse", bibleVerse);
  formData.append("bible_verse_source", bibleVerseSource);
  formData.append("faith_confession", faithConfession);
  formData.append("meditate_question", meditateQuestion);
  formData.append("meditate_verse", meditateVerse);
  formData.append("meditate_verse_source", meditateVerseSource);
  formData.append("daily_verse_morning", dailyVerseMorning);
  formData.append("daily_verse_evening", dailyVerseEvening);
  formData.append("is_locked", !isAvailable);
  formData.append("page_count", pageCount);
  for (let i = 0; i < documents.length; i++) {
    formData.append("documents", documents[i]);
  }

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/books/${bookId}/pages/`, data: formData, headers: getHeaders() })
    .then((response) => {
      if (response.status === 201) {
        responseData.status = "success";
        responseData.message = "Page créée avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Création impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Création impossible";
    });

  return responseData;
};

export const updatePageRequest = async (
  bookId,
  languageId,
  id,
  type,
  order,
  title,
  author,
  dayDate,
  day,
  bibleVerse,
  bibleVerseSource,
  faithConfession,
  meditateQuestion,
  meditateVerse,
  meditateVerseSource,
  dailyVerseMorning,
  dailyVerseEvening,
  isAvailable
) => {
  const uri = getApiUri();

  const data = {
    book_id: bookId,
    language_id: languageId,
    type: type,
    order: order,
    title: title,
    author: author,
    day_date: dayDate,
    day: day,
    bible_verse: bibleVerse,
    bible_verse_source: bibleVerseSource,
    faith_confession: faithConfession,
    meditate_question: meditateQuestion,
    meditate_verse: meditateVerse,
    meditate_verse_source: meditateVerseSource,
    daily_verse_morning: dailyVerseMorning,
    daily_verse_evening: dailyVerseEvening,
    is_locked: !isAvailable,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "PATCH", url: `${uri}/api/v1/books/${bookId}/pages/${id}/`, data: data, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "Page modifiée avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Modification impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Modification impossible";
    });

  return responseData;
};

export const deleteBookRequest = async ( bookId, id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "message": null,
  };

  await axios({ method: "DELETE", url: `${uri}/api/v1/books/${bookId}/pages/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 204) {
        responseData.status = "success";
        responseData.message = "Page supprimée avec succès";
      } else {
        responseData.status = "error";
        responseData.message = "Suppression impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Suppression impossible";
    });

  return responseData;
};
