import axios from "axios";
import camelize from "camelize";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { Facebook } from "react-content-loader";
import ReactSwitch from "react-switch";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "../utils";

const EditUser = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [interfaceLanguages, setInterfaceLanguages] = useState({});
  const [readingLanguages, setReadingLanguages] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [readingMode, setReadingMode] = useState("");
  const [interfaceLanguage, setInterfaceLanguage] = useState("");
  const [readingLanguage, setReadingLanguage] = useState("");
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    axios({ method: "GET", url: `http://localhost:5000/users/id/${userId}` })
      .then((response) => {
        if (!isEmpty(response.data)) {
          if (response.data.status) {
            setUser(camelize(response.data));
            updateFields();
          }
        } else {
          toast.error(
            "Une erreur interne est survenue. Veuillez recharger la page"
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error(
          "Une erreur interne est survenue. Veuillez recharger la page"
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios({ method: "GET", url: "http://localhost:5000/languages/interface" })
      .then((response) => {
        if (!isEmpty(response.data.languages)) {
          setInterfaceLanguages(camelize(response.data.languages));
        } else {
          toast.warning("Aucune langue pour n'est disponible pour la lecture d'interface");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error(
          "Une erreur interne est survenue. Veuillez recharger la page"
        );
      });
  }, []);

  useEffect(() => {
    axios({ method: "GET", url: "http://localhost:5000/languages/reading" })
      .then((response) => {
        if (!isEmpty(response.data.languages)) {
          setReadingLanguages(camelize(response.data.languages));
        } else {
          toast.warning("Aucune langue n'est disponible pour la lecture d'IM");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error(
          "Une erreur interne est survenue. Veuillez recharger la page"
        );
      });
  }, []);

  useEffect(() => {
    updateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updateFields = () => {
    if (!isEmpty(user)) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setReadingMode(user.readingMode);
      setInterfaceLanguage(user.interfaceLanguage);
      setReadingLanguage(user.readingLanguage);
      setReadingMode(user.readingMode);
      setIsActive(user.isActive);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      reading_mode: readingMode,
      is_active: isActive,
    };

    await axios({ method: "PUT", url: `http://localhost:5000/users/${userId}`, data: data })
      .then((response) => {
        if (response.data) {
          if (response.data.status) {
            navigate("/manage/user", {
              state: {
                message: "Utilisateur modifié avec succès",
                status: true,
              },
            });
          } else {
            toast.error(
              "Certaines informations existent déjà dans la base de données"
            );
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur interne est survenue. Veuillez réessayer plus tard"
        );
        setLoading(false);
      });
  };

  const handleDelete = async () => {
    setShowModal(false);
    setLoading(true);

    await axios({ method: "DELETE", url: `http://localhost:5000/users/${userId}` })
      .then((response) => {
        if (response.data) {
          if (response.data.status) {
            navigate("/manage/user", {
              state: {
                message: "Utilisateur supprimé avec succès",
                status: true,
              },
            });
          } else {
            toast.error("Suppression de l'utilisateur impossible");
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur interne est survenue. Veuillez réessayer plus tard"
        );
        setLoading(false);
      });
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Suppresion IM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet utilisateur définitivement ?
          Cela entraînera la perte de tous ses achats.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>
            Oui
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Non
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container-fluid page-body-wrapper">
        <SideNavigation />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">
                      Modification Utilisateur
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <form className="forms-sample" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="year-form">Nom</label>
                          <input
                            type="text"
                            className="form-control"
                            id="last-name-form"
                            placeholder="Nom"
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="year-form">Prénom(s)</label>
                          <input
                            type="text"
                            className="form-control"
                            id="first-name-form"
                            placeholder="Prénom(s)"
                            required
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="year-form">Adresse e-mail</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email-form"
                            placeholder="E-mail"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="year-form">Numéro téléphone</label>
                          <input
                            type="tel"
                            className="form-control"
                            id="phone-number-form"
                            placeholder="Numéro téléphone"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="month-form">Mode de lecture</label>
                          <select
                            className="form-control"
                            id="reading-mode-form"
                            value={readingMode}
                            onChange={(e) => setReadingMode(e.target.value)}
                          >
                            <option value="Book">Original</option>
                            <option value="Virtual">Virtuel</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="month-form">Langue d'interface</label>
                          <select
                            className="form-control"
                            id="reading-mode-form"
                            value={interfaceLanguage}
                            onChange={(e) => setInterfaceLanguage(e.target.value)}
                          >
                            {interfaceLanguages.map((language) => {
                              return <option value={language.name}>{language.name}</option>
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="month-form">Langue de lecture</label>
                          <select
                            className="form-control"
                            id="reading-mode-form"
                            value={readingLanguage}
                            onChange={(e) => setReadingLanguage(e.target.value)}
                          >
                            {readingLanguages.map((language) => {
                              return <option value={language.name}>{language.name}</option>
                            })}=
                          </select>
                        </div>

                        <div className="form-check form-check-flat form-check-primary">
                          <label htmlFor="is-active-form">Disponible</label>
                          <div>
                            <ReactSwitch
                              checked={isActive}
                              onChange={(check) => setIsActive(check)}
                              uncheckedIcon={false}
                              checkedIcon={false}
                            />
                          </div>
                        </div>
                        <button type="submit" className="btn btn-warning mr-2 text-white">
                          Modifier Utilisateur
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger mr-2"
                          onClick={() => setShowModal(true)}
                        >
                          Supprimer Utilisateur
                        </button>
                        <button
                          className="btn btn-secondary mr-2"
                          onClick={() => navigate("/manage/user")}
                        >
                          Annuler
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default EditUser;
