import axios from "axios";
import camelize from "camelize";
import { getApiUri, getHeaders } from "./NetworkService";

export const getLanguagesRequest = async () => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/languages/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des langues";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = error.response.data.detail ? error.response.data.detail : "Erreur lors de la récupération des langues";
    });

  return responseData;
};

export const getLanguageRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/languages/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération de la langue";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération de la langue";
    });

  return responseData;
};

export const createLanguageRequest = async ( name, interfaceLanguage, readingLanguage ) => {
  const uri = getApiUri();

  const data = {
    name: name,
    interface: interfaceLanguage,
    reading: readingLanguage,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/languages/`, data: data, headers: getHeaders() })
    .then((response) => {
      if (response.status === 201) {
        responseData.status = "success";
        responseData.message = "Langue créée avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Création impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Création impossible";
    });

  return responseData;
};

export const updateLanguageRequest = async ( id, name, interfaceLanguage, readingLanguage ) => {
  const uri = getApiUri();

  const data = {
    name: name,
    interface: interfaceLanguage,
    reading: readingLanguage,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "PATCH", url: `${uri}/api/v1/languages/${id}/`, data: data, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "Langue modifiée avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Modification impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Modification impossible";
    });

  return responseData;
};

export const deleteLanguageRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "message": null,
  };

  await axios({ method: "DELETE", url: `${uri}/api/v1/languages/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 204) {
        responseData.status = "success";
        responseData.message = "Langue supprimée avec succès";
      } else {
        responseData.status = "error";
        responseData.message = "Suppression impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Suppression impossible";
    });

  return responseData;
};
