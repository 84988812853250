import React from "react";
import ReactSwitch from "react-switch";

const DailyPageTypeForm = ({
  isAvailable,
  pageCount,
  setPageCount,
  setIsAvailable,
  handleUpdateDocuments,
  title,
  setTitle,
  author,
  setAuthor,
  day,
  setDay,
  dayDate,
  setDayDate,
  bibleVerse,
  setBibleVerse,
  bibleVerseSource,
  setBibleVerseSource,
  faithConfession,
  setFaithConfession,
  meditateQuestion,
  setMeditateQuestion,
  meditateVerse,
  setMeditateVerse,
  meditateVerseSource,
  setMeditateVerseSource,
  dailyVerseMorning,
  setDailyVerseMorning,
  dailyVerseEvening,
  setDailyVerseEvening,
}) => {
  return (
    <div className="row">
      <div className="col-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="page-count-form">
                Nombre de pages <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                id="page-count-form"
                value={pageCount}
                onChange={(e) => setPageCount(e.target.value)}
              >
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>

            <div className="form-group">
              <label>
                Fichier source 1 <span className="text-danger">*</span>
              </label>
              <div className="input-group col-xs-12">
                <input
                  type="file"
                  className="form-control file-upload-info"
                  placeholder="Fichier source"
                  required
                  onChange={(e) => {
                    handleUpdateDocuments(e.target.files[0], 0);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>
                Fichier source 2 <span className="text-danger">*</span>
              </label>
              <div className="input-group col-xs-12">
                <input
                  type="file"
                  className="form-control file-upload-info"
                  placeholder="Fichier source 2"
                  required
                  onChange={(e) => {
                    handleUpdateDocuments(e.target.files[0], 1);
                  }}
                />
              </div>
            </div>
            {parseInt(pageCount) > 2 && (
              <div className="form-group">
                <label>
                  Fichier source 3 <span className="text-danger">*</span>
                </label>
                <div className="input-group col-xs-12">
                  <input
                    type="file"
                    className="form-control file-upload-info"
                    placeholder="Fichier source 3"
                    required
                    onChange={(e) => {
                      handleUpdateDocuments(e.target.files[0], 2);
                    }}
                  />
                </div>
              </div>
            )}
            {parseInt(pageCount) > 3 && (
              <div className="form-group">
                <label>
                  Fichier source 4 <span className="text-danger">*</span>
                </label>
                <div className="input-group col-xs-12">
                  <input
                    type="file"
                    className="form-control file-upload-info"
                    placeholder="Fichier source 4"
                    required
                    onChange={(e) => {
                      handleUpdateDocuments(e.target.files[0], 3);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="form-group">
              <label htmlFor="title-form">
                Titre <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="title-form"
                placeholder="Thème du jour"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="author-form">
                Auteur <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                id="author-form"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              >
                <option value="Bishop Patrick">Bishop Patrick</option>
                <option value="Révérend Fara">Révérend Fara</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="day-form">
                Jour <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="day-form"
                placeholder="Exemple : 15"
                required
                value={day}
                onChange={(e) => setDay(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="day-date-form">
                Jour Complet <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="day-date-form"
                placeholder="Exemple : Alakamisy 03 Martsa"
                required
                value={dayDate}
                onChange={(e) => setDayDate(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="verse-form">Verset</label>
              <textarea
                className="form-control"
                id="verse-form"
                rows="4"
                value={bibleVerse}
                onChange={(e) => setBibleVerse(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="verse-source-form">Source du verset</label>
              <input
                type="text"
                className="form-control"
                id="verse-source-form"
                placeholder="Exemple : Salamo 105:1"
                value={bibleVerseSource}
                onChange={(e) => setBibleVerseSource(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="faith-confession-form">Confession de foi</label>
              <textarea
                className="form-control"
                id="faith-confession-form"
                rows="4"
                value={faithConfession}
                onChange={(e) => setFaithConfession(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="meditate-question-form">Méditation du jour</label>
              <textarea
                className="form-control"
                id="meditate-question-form"
                rows="4"
                value={meditateQuestion}
                onChange={(e) => setMeditateQuestion(e.target.value)}
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="meditate-verse-form">Verset à méditer</label>
              <textarea
                className="form-control"
                id="meditate-verse-form"
                rows="4"
                value={meditateVerse}
                onChange={(e) => setMeditateVerse(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="meditate-verse-source-form">
                Source du verset à méditer
              </label>
              <input
                type="text"
                className="form-control"
                id="meditate-verse-source-form"
                placeholder="Exemple : Salamo 107:11"
                value={meditateVerseSource}
                onChange={(e) => setMeditateVerseSource(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="daily-verse-morning-form">
                Verset(s) du jour matinée
              </label>
              <input
                type="text"
                className="form-control"
                id="daily-verse-morning-form"
                placeholder="Salamo 15"
                value={dailyVerseMorning}
                onChange={(e) => setDailyVerseMorning(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="daily-verse-evening-form">
                Verset(s) du jour soirée
              </label>
              <input
                type="text"
                className="form-control"
                id="daily-verse-evening-form"
                placeholder="Ohabolana 30-31"
                value={dailyVerseEvening}
                onChange={(e) => setDailyVerseEvening(e.target.value)}
              />
            </div>

            <div className="form-check form-check-flat form-check-primary">
              <label htmlFor="cover-page-form">Disponible</label>
              <div>
                <ReactSwitch
                  checked={isAvailable}
                  onChange={(check) => setIsAvailable(check)}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-primary mr-2">
              Ajouter Page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyPageTypeForm;
