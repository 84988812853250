import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Facebook } from "react-content-loader";
import ReactSwitch from "react-switch";
import Select from "react-select";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { isEmpty } from "../utils";
import { getLanguagesRequest } from "../services/LanguageService";
import { createBookRequest } from "../services/BookService";

const AddNewBook = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [languages, setLanguages] = useState([]);
  const [chosenLanguages, setChosenLanguages] = useState([]);

  const [month, setMonth] = useState("Janvier");
  const [year, setYear] = useState(2022);
  const [price, setPrice] = useState();
  const [cover, setCover] = useState(null);
  const [isAvailable, setIsAvailable] = useState(true);

  useEffect(() => {
    const fetchLanguageData = async () => {
      await getLanguages();
    }

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchLanguageData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLanguages = async () => {
    const response = await getLanguagesRequest();

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        const temp = [];
        response.data.map((language) => {
          return temp.push({ value: language.id, label: language.name });
        });
        setLanguages(temp);
      }
    } else {
      setLanguages([]);
      toast.warning(response.message);
    }

    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const languagesIds = chosenLanguages.map((language) => {
      return language.value;
    });

    const response = await createBookRequest(year, month, cover, price, isAvailable, languagesIds);

    if (response.status === "success") {
      toast.success(response.message);
      setLoading(false);
    } else {
      toast.error(response.message);
      setLoading(false);
    }
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />
      <div className="container-fluid page-body-wrapper">
        <SideNavigation currentPage="addBook" />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Ajout IM</h3>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <form className="forms-sample" enctype="multipart/form-data" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="month-form">
                            Mois <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="month-form"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                          >
                            <option value="january">Janvier</option>
                            <option value="february">Février</option>
                            <option value="march">Mars</option>
                            <option value="april">Avril</option>
                            <option value="may">Mai</option>
                            <option value="june">Juin</option>
                            <option value="july">Juillet</option>
                            <option value="august">Août</option>
                            <option value="september">Septembre</option>
                            <option value="october">Octobre</option>
                            <option value="november">Novembre</option>
                            <option value="december">Décembre</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="year-form">
                            Année <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="year-form"
                            placeholder="Année"
                            required
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="price-form">
                            Prix (Ar) <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="price-form"
                            placeholder="Prix (en ariary)"
                            required
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="cover-page-form">
                            Photo de couverture{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group col-xs-12">
                            <input
                              type="file"
                              id="cover-page-form"
                              className="form-control file-upload-info"
                              placeholder="Veuillez choisir un fichier"
                              required
                              onChange={(e) =>
                                setCover(e.target.files[0])
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="languages-choice-form">Langues</label>
                          <Select
                            options={languages}
                            isMulti={true}
                            onChange={(values) => setChosenLanguages(values)}
                            placeholder="Veuillez choisir une ou plusieurs langues"
                          />
                        </div>

                        <div className="form-check">
                          <label htmlFor="cover-page-form">Disponible</label>
                          <div>
                            <ReactSwitch
                              checked={isAvailable}
                              onChange={(check) => setIsAvailable(check)}
                              uncheckedIcon={false}
                              checkedIcon={false}
                            />
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary mt-2">
                          Ajouter IM
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default AddNewBook;
