import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { Facebook } from "react-content-loader";
import { useNavigate, useParams } from "react-router-dom";

const AddNewParagraph = () => {
  const navigate = useNavigate();
  const { pageId } = useParams();
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState(0);
  const [content, setContent] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");

  //   const uploadFile = async (file) => {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("file_type", file.type);
  //     formData.append("year", year);
  //     formData.append("month", month);

  //     await axios({ method: "POST", url: "http://localhost:5000/upload/cover", data: formData })
  //       .then((response) => {
  //         console.log(response);
  //         console.log(response.data);
  //         if (response.data) {
  //           console.log(response.data);
  //           if (response.data.status) {
  //             toast.success("Fichier ajouté avec succès");
  //           } else {
  //             toast.error("Ajout du fichier impossible");
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         toast.error(
  //           "Une erreur interne est survenue lors de l'ajout du fichier"
  //         );
  //       });
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log("Page ID : " + pageId);

    const data = {
      order: order,
      content: content,
      dark_mode: darkMode,
      background_color: backgroundColor,
      page_id: pageId,
    };

    // await uploadFile(coverPicture);

    await axios({ method: "POST", url: `http://localhost:5000/paragraphs/${pageId}`, data: data })
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          if (response.data.status) {
            navigate(`/manage/page/${pageId}/paragraph`, {
              state: {
                message: "Paragraphe ajouté avec succès",
                status: true,
              },
            });
          } else {
            toast.error(
              "L'ordre pour la page est déjà pris"
            );
          }
          setLoading(false);
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur interne est survenue. Veuillez réessayer plus tard"
        );
        setLoading(false);
      });
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />
      <div className="container-fluid page-body-wrapper">
        <SideNavigation />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Ajout Paragraphe</h3>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <form className="forms-sample" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="order-form">
                            Ordre <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="order-form"
                            placeholder="Ordre d'emplacement sur la page"
                            required
                            value={order}
                            min={1}
                            onChange={(e) => setOrder(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="content-form">
                            Contenu <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="content-form"
                            rows="4"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label htmlFor="mode-form">
                            Mode <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="mode-form"
                            value={!darkMode ? "light" : "dark"}
                            onChange={(e) =>
                              e.target.value === "light"
                                ? setDarkMode(false)
                                : setDarkMode(true)
                            }
                          >
                            <option value="light">Clair</option>
                            <option value="dark">Sombre</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="background-color-form">
                            Couleur de fond{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="background-color-form"
                            placeholder="Exemple : #FFFFFF"
                            required
                            value={backgroundColor}
                            onChange={(e) => setBackgroundColor(e.target.value)}
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary mt-2 mr-2"
                        >
                          Ajouter Paragraphe
                        </button>
                        <button
                          className="btn btn-secondary mt-2"
                          onClick={() =>
                            navigate(`/manage/page/${pageId}/paragraph`)
                          }
                        >
                          Annuler
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default AddNewParagraph;
